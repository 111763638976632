import { AppData } from '../../types/translations.types';

export class UserSideNavigationMenuLocalizedLabels {
	public backToMenuText: string;
	public changeCustomerText: string;
	public customerInvoiceNumberText: string;
	public customerTypeText: string;
	public customerVatText: string;
	public invoiceText: string;
	public jobsitesText: string;
	public signOutText: string;
	public statisticsText: string;
	public switchingCustomerText: string;
	public gettingCustomersText: string;
	public viewAccountText: string;
	public welcomeText: string;
	public notification: string;
	public savedProducts: string;
	public administrateUsers: string;
	public smartControlButtonText: string;
	public smartControlModalTitle: string;
	public smartControlModalMessage: string;
	public smartControlModalConfirmButtonText: string;
	public smartControlModalCancelButtonText: string;

	constructor(appData: AppData = <AppData>{}) {
		if (!appData) {
			appData = <AppData>{};
		}
		this.backToMenuText = appData.userNavigation?.backToMenu || 'Back to menu';
		this.changeCustomerText = appData.userNavigation?.changeCustomer || 'Change customer';
		this.customerInvoiceNumberText = appData.global?.customerId || '';
		this.customerTypeText = appData.global?.type || '';
		this.customerVatText = appData.global?.vat || '';
		this.invoiceText = appData.myPages?.invoices?.invoiceTitle || '';
		this.jobsitesText = appData.userNavigation?.jobsitesText || '';
		this.signOutText = appData.framework?.signOutText || '';
		this.statisticsText = appData.statistics?.statistics || '';
		this.switchingCustomerText = appData.userNavigation?.switchingCustomer || '';
		this.gettingCustomersText = appData.userNavigation?.gettingCustomers || '';
		this.viewAccountText = appData.userNavigation?.viewAccount || 'View account';
		this.welcomeText = appData.userNavigation?.welcome || 'Welcome';
		this.notification = appData.userNavigation?.notificationsText || 'Notification';
		this.savedProducts = appData.userNavigation?.savedProducts || 'Saved Products';
		this.administrateUsers = appData.userNavigation?.administrateUsers || 'Administrate users';
		this.smartControlButtonText = appData.userNavigation?.smartControlButtonText;
		this.smartControlModalTitle = appData.userNavigation?.smartControlModalTitle;
		this.smartControlModalMessage = appData.userNavigation?.smartControlModalMessage;
		this.smartControlModalConfirmButtonText = appData.userNavigation?.smartControlModalConfirmButtonText;
		this.smartControlModalCancelButtonText = appData.userNavigation?.smartControlModalCancelButtonText;
	}
}
